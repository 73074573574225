import Form from "./view/Form";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";
function App() {
  return (
    <>
      <Navbar />
      <Form />
      <a
        href="https://wa.link/csjtxb"
        className="whatsapp-float"
        target="_blank"
        rel="noreferrer"
      >
        <i className="bx bxl-whatsapp"></i>
      </a>
      <Footer />
    </>
  );
}

export default App;
