import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "../styles/customForm.css";
import { serviceGetData } from "../service/getData";

const data = {
  cedula: "",
  concepto: "",
  correo: "",
  contrato: "",
  valor: "",
  inicio_pago: "",
  fin_pago: "",
};
const Form = () => {
  const [user, setUser] = useState(data);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpen, setOpen] = useState(false);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addOneMonth = () => {
    if (user.inicio_pago) {
      const currentDate = new Date(user.inicio_pago);
      currentDate.setMonth(currentDate.getMonth() + 1); // Sumar un mes
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const newDate = `${year}-${month}-${day}`;

      return newDate;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url =
        "https://priority-api-gac-production.up.railway.app/api/mercadopago/suscripciones";
      const response = await axios.post(url, {
        correo: user.correo,
        contrato: user.contrato,
        valor: parseInt(user.valor, 10),
        inicio_pago: user.inicio_pago + "T14:06:15.709-04:00",
        fin_pago: user.fin_pago + "T14:06:15.709-04:00",
        concepto: selectedOption,
      });
      if (response.data?.status === 400) {
        toast.error(response?.data?.message, { duration: 10000 });
      } else {
        window.open(response.data.link);
        setUser(data);
      }
      setLoading(false);
    } catch (error) {
      toast.warning("ha ocurrido algo, intentelo de nuevo!");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const consultData = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const {
        data: { data },
      } = await serviceGetData(user.cedula);
      console.log(data);
      setUser({
        ...user,
        contrato: data.contrato,
        correo: data.correo,
      });
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <Toaster />
      <div onClick={() => setOpen(!isOpen)} className="btnInfo">
        + Información
      </div>
      {isOpen && (
        <>
          <div className="darkBG" onClick={() => setOpen(!isOpen)} />
          <p className="show-info">
            ¡Bienvenido a Grupo Alianza Colombia! Nos complace ofrecerte una
            experiencia de suscripción conveniente y sin complicaciones. Con
            nuestro servicio de pago automático, puedes inscribir tu tarjeta de
            débito o crédito para que tus pagos de suscripción se realicen de
            manera automática cada mes. Esto garantiza que puedas disfrutar de
            todos los beneficios de tu suscripción sin interrupciones ni
            preocupaciones. ¡Descubre cómo inscribir tu tarjeta de débito o
            crédito a continuación!
          </p>
        </>
      )}

      {user?.contrato.length === 0 && (
        <div className="center mt-5">
          <div className="form-modal">
            <form className="form" onSubmit={consultData} id="form-send-data">
              <div className="separator">
                <hr className="line" />
                <p>Pago de tu membresía</p>
                <hr className="line" />
              </div>
              <span>
                Para disfrutar de nuestros increíbles servicios de tu membresia,
                solo necesitas proporcionarnos el número de cédula del titular.
                ¡No esperes más para acceder a ofertas especiales!
              </span>
              <div className="credit-card-info--form">
                <label className="input_label">Cédula ciudadania</label>
                <input
                  className="input_field"
                  required
                  type="number"
                  min={0}
                  placeholder="Ingresa tu cédula de ciudadania"
                  name="cedula"
                  value={user.cedula}
                  onChange={handleChange}
                />
              </div>

              <button className="accepTerms" disabled={loading}>
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Enviar"
                )}
              </button>
            </form>
          </div>
        </div>
      )}

      {user?.contrato.length > 0 && (
        <div className="center mt-5">
          <div className="form-modal">
            <form className="form" onSubmit={handleSubmit} id="form-send-data">
              <div className="separator">
                <hr className="line" />
                <p>Pago de tu membresía</p>
                <hr className="line" />
              </div>
              <div className="credit-card-info--form">
                <div className="input_container">
                  <label className="input_label" htmlFor="selectOption">
                    Concepto
                  </label>
                  <select
                    id="selectOption"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    className="form-select"
                    required
                  >
                    <option value="">Seleccionar</option>
                    <option value="Cuotas de Financiación">
                      Cuotas de Financiación
                    </option>
                    <option value="Saldo Cuota Inicial">
                      Saldo Cuota Inicial
                    </option>
                    <option value="Derechos Afiliacion">
                      Derechos Afiliacion
                    </option>
                  </select>
                </div>
                <div className="input_container">
                  <label className="input_label">Contrato</label>
                  <input
                    className="input_field"
                    required
                    type="text"
                    placeholder="Ingresa tu contrato"
                    name="contrato"
                    value={user.contrato.toUpperCase()}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="input_container">
                  <label className="input_label">Correo electrónico</label>
                  <input
                    className="input_field"
                    required
                    type="email"
                    placeholder="ejemplo@correo.com"
                    name="correo"
                    value={user.correo}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="input_container">
                  <label className="input_label">Monto </label>
                  <input
                    className="input_field"
                    required
                    type="number"
                    placeholder="200.000"
                    min="12000"
                    name="valor"
                    value={user.valor}
                    onChange={handleChange}
                  />
                </div>
                <div className="input_container">
                  <label className="input_label">Fecha inicio de pago </label>
                  <input
                    type="date"
                    name="inicio_pago"
                    min={getCurrentDate()}
                    value={user.inicio_pago}
                    onChange={handleChange}
                    className="input_field"
                    required
                  />
                </div>
                <div className="input_container">
                  <label className="input_label">Fecha fin de pago </label>
                  <input
                    type="date"
                    name="fin_pago"
                    min={addOneMonth()}
                    value={user.fin_pago}
                    onChange={handleChange}
                    className="input_field"
                    required
                  />
                </div>
                <div className="">
                  <label htmlFor="terminos" className="">
                    Con la habilitación del Check, haciendo uso pleno de mis
                    capacidades cognitivas, de manera libre, espontánea y
                    voluntaria, autorizo la suscripción al Plan de Pago de la
                    plataforma de pagos <b>MERCADOPAGO </b>y DECLARO que conozco
                    y tengo pleno conocimiento de los términos y condiciones de
                    la suscripción al Plan de Pago de <b>MERCADOPAGO</b> que se
                    encuentra publicada en la parte inferior de la página{" "}
                    <a
                      href="web www.mercadopago.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      web www.mercadopago.com
                    </a>{" "}
                    o puede ser consultada directamente en el siguiente link{" "}
                    <a
                      href="https://www.mercadopago.com.co/ayuda/194"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.mercadopago.com.co/ayuda/194
                    </a>
                  </label>
                  <b>Autorizo</b>
                  <button className="accepTerms" disabled={loading}>
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Si"
                    )}
                  </button>
                  <a
                    href="https://grupoalianzacolombia.com/mercadopago"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="notAccept"
                  >
                    No
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
