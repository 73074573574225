import axios from "axios";

const serviceGetData = async (cedula) => {
  const url =
    "https://priority-api-gac-production.up.railway.app/api/mercadopago/data-client";
  const response = await axios.post(url, { cedula: cedula });
  return response;
};

export { serviceGetData };
