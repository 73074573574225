import React from "react";
import "boxicons/css/boxicons.min.css"; // Asegúrate de importar el CSS de Boxicons

const Navbar = () => {
  return (
    <nav className="navbar fixed-top navbar-expand-lg bg-navbar">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <img
          src="/logo.png"
          alt="Grupo Alianza Colombia Viajar"
          height={55}
          width={200}
        />
        <div className="navbar-icons">
          <a
            href="https://www.instagram.com/grupoalianzacolombia"
            target="_blank"
            rel="noreferrer"
            className="round-gray"
          >
            <i className="bx bxl-instagram icon"></i>
          </a>

          <a
            href="https://www.tiktok.com/@grupoalianzacolombia?lang=es"
            target="_blank"
            rel="noreferrer"
            className="round-gray"
          >
            <i className="bx bxl-tiktok icon"></i>
          </a>
          <a
            href="https://www.facebook.com/Grupoalianzacolombia"
            target="_blank"
            rel="noreferrer"
            className="round-gray"
          >
            <i className="bx bxl-facebook icon"></i>
          </a>

          <a
            href="https://www.youtube.com/@grupoalianzacolombia"
            target="_blank"
            rel="noreferrer"
            className="round-gray"
          >
            <i className="bx bxl-youtube icon"></i>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
